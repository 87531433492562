<template>
	<div>
		<div class="mt-4">
			<svg-active-tasks-icon class="p-0" />
			<h1 class="ml-4 mb-0" v-if="tasks">Aktívne úlohy ({{tasks[0].length}})</h1>
		</div>
		<z-group-table v-if="tasks"
			class="--clickable-rows"
			:tableItems="tasks[0]" :tableFields="isAdmin ? adminActiveTaskFields : memberActiveTaskFields"
			@rowClicked="navigateToTask"
		/>
		<div class="mt-5">
			<svg-inactive-tasks-icon/>
			<h1 class="ml-4 p-0" v-if="tasks">História úloh ({{tasks[1].length}})</h1>
		</div>
		<z-group-table v-if="tasks"
			class="inactive-tasks --clickable-rows" :tableItems="tasks[1]" :tableFields="isAdmin ? adminInactiveTaskFields : memberInactiveTaskFields" :tableHeader="'d-none'"
			@itemToReactivate="reactivateTask" @groupToRemove="$refs['remove-task-modal'].showModal($event)" @rowClicked="navigateToTask" />
		<a-remove-task-modal ref="remove-task-modal" v-if="tasks"
			:modalConfig="modalConfig" @remove="removeTask"/>
	</div>
</template>

<script>
import moment from 'moment-timezone'
import { partition, sortBy } from 'lodash'
import { mapGetters } from 'vuex'
import wAxios from '@/plugins/w/axios'

const api = {
	admin: {
		getTasks: (gid) => wAxios.get_auth_data(`v1/group-admin/groups/${gid}/tasks`),
		reactivateTask: (gid, tid, newDeadline) => wAxios.patch_auth_data(`v1/group-admin/groups/${gid}/tasks/${tid}`, newDeadline),
		removeTask: (gid, tid) => wAxios.delete_auth_data(`v1/group-admin/groups/${gid}/tasks/${tid}`)
	},
	member: {
		getTasks: (gid) => wAxios.get_auth_data(`v1/group-member/groups/${gid}/tasks`),
	}
}

export default {
	components: {
		'z-group-table': () => import('@/plugins/lib@groups/groups/z-group-table.vue'),
		'a-remove-task-modal': () => import('@/plugins/lib@groups/groups/modals/a-remove-group-modal.vue'),
		'svg-active-tasks-icon': () => import('@/plugins/appzmudri/_theme/icon/active-tasks.svg?inline'),
		'svg-inactive-tasks-icon': () => import('@/plugins/appzmudri/_theme/icon/inactive-tasks.svg?inline'),
	},

	data() {
		return {
			adminActiveTaskFields: [
				{key: 'courseImg', label: 'Kurz'},
				{key: 'course', label: ''},
				{key: 'starts_at', label: 'Termín zadania úlohy'},
				{key: 'deadline', label: 'Termín splnenia úlohy'},
				{key: 'watch_or_reactivate', label: ''},
			],
			adminInactiveTaskFields: [
				{key: 'courseImg', label: 'Kurz'},
				{key: 'course', label: '', class: 'white'},
				{key: 'starts_at', label: 'Termín zadania úlohy', class: 'white'},
				{key: 'deadline', label: 'Termín splnenia úlohy', class: 'white'},
				{key: 'watch_or_reactivate', label: ''},
				// {key: 'remove', label: '', class: 'white'}
			],
			memberActiveTaskFields: [
				{key: 'courseImg', label: 'Kurz'},
				{key: 'course', label: ''},
				{key: 'starts_at', label: 'Termín zadania úlohy'},
				{key: 'deadline', label: 'Termín splnenia úlohy'},
				{key: 'exams_count', label: 'Počet bodov'},
				// {key: 'go_to_course', label: ''},
			],
			memberInactiveTaskFields: [
				{key: 'courseImg', label: 'Kurz'},
				{key: 'course', label: ''},
				{key: 'starts_at', label: 'Termín zadania úlohy'},
				{key: 'deadline', label: 'Termín splnenia úlohy'},
				{key: 'exams_count', label: 'Počet bodov'},
				{key: 'space', label: ''},
			],
			tasks: null,
			modalConfig: {
				adminHeaderText: 'Odstrániť úlohu',
				adminBodyText: 'Ste si istý, že chcete odstraniť túto úlohu',
			}
		}
	},

	async created() {
		await this._loadTasks()
	},

	computed: {
		...mapGetters('wAuth', [
			'user'
		]),
		isAdmin() {
			return this.user.profile.type == 'teacher'
		}
	},

	methods: {
		async reactivateTask(tid) {
			this.$wToast.clear()
			try {
				this.$wToast.success('Úloha bola úspešne reaktivovaná')
				await api.admin.reactivateTask(this.$route.params.id, tid, {
					'deadline': moment().add(7, 'days').format('YYYY-MM-DD HH:mm:ss').toString()
				})
				this.$nextTick(async () => {
					await this._loadTasks()
				})
			} catch (e) {
				this.$wToast.error(e)
			}
		},
		async removeTask(tid) {
			this.$wToast.clear()
			try {
				this.$wToast.success('Úloha bola úspešne odstránená')
				await api.admin.removeTask(this.$route.params.id, tid.id)
				this.$nextTick(async () => {
					await this._loadTasks()
				})
			} catch (e) {
				this.$wToast.error(e)
			}
		},
		async navigateToTask(item, index, event) {
			this.$router.push(`${this.$route.path}/uloha/${item.id}`)
		},
		async _loadTasks() {
			const r = this.isAdmin ? await api.admin.getTasks(this.$route.params.id) : await api.member.getTasks(this.$route.params.id)
			sortBy(r, e=> e.course.name)
			this.tasks = partition(r, (e) => {
				return !e.is_started || e.is_started && e.is_active
			})
		}
	}
}
</script>

<style lang="scss" scoped>
	h1 {
		font-size: 20px;
		display: inline-block;
		color:#2B2E4A;
	}

	::v-deep .white {
		color: white !important;
	}

	.inactive-tasks {
		color: white !important;
		background-color: #0064FF;

		&::v-deep p {
			color: white !important;
		}
	}
</style>